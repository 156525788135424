.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.changeTheme{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.changeTheme span{
  font-size: 25px;
  color: #a09d9d;
}

.logo img{
  /* margin-top: 20px;  */
  width: 250px;
}

.login ul{
  padding: 0px 20px 0px 20px;
}

.loading{
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header{
  padding-top: 20px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  gap: 30px;
  align-items: center;
  padding: 5px;
}

.header .menu{
  display: flex;
  align-items: center;
  gap: 1%;
  gap: 3px;
}

.logOut{
  cursor: pointer;
}

.completed{
  color: blue;
}

.container{
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.darkBackground{
  background-color: #818181;
}

.word, .login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 50vh; */
  /* margin-top:  */
}

.ant-tag{
  font-size: 14px;
}

.ant-card-head{
  font-size: 18px;
}

.randomButton{
  margin: 1px;
}

@media (max-width: 1250px) {
  .header{
    font-size: 12px;
  }

  .header .logo img{
    width: 120px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
